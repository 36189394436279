import classNames from 'classnames';
import React, { ReactNode } from 'react';

import Button from 'common-ui-components/Button';
import { KEYBOARD_NAVIGATION_OPTION_CLASS_NAME } from 'common-ui-components/Dropdown/keyboardNavigationHook';
import { Heading3 } from 'common-ui-components/Typograhpy';

import style from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/AutocompleteResultCategory/style.module.scss';

interface Props<T> {
  entityType: 'topics' | 'organizations' | 'people';
  entities: T[];
  renderChild: (item: T) => ReactNode;
  entitiesCount: number;
  handleClick: (item: T) => void;
}

export default function AutocompleteResultCategory<T extends { id: string }>({
  entities,
  renderChild,
  entityType,
  entitiesCount,
  handleClick,
}: Props<T>) {
  if (entitiesCount === 0) return null;

  return (
    <div className={style.autoCompleteResultCategory}>
      <div className={style.heading}>
        <Heading3 className={style.title}>{entityType}</Heading3>
        {entitiesCount > 5 && <span className={style.info}>{`There are ${entitiesCount} total results. Please narrow your search`}</span>}
      </div>
      {entities.map((entity) => (
        <Button
          key={entity.id}
          className={classNames(style.option, KEYBOARD_NAVIGATION_OPTION_CLASS_NAME)}
          onClick={() => handleClick(entity)}
        >
          {renderChild(entity)}
        </Button>
      ))}
    </div>
  );
}
